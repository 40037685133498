#root {
	font-family: '';
	text-transform: uppercase;

	margin: 0; padding: 0;
	
	background-image: url('../images/space-background.jpg');
	background-color: #000000;
	
	color: #FFFFFF;
}
  
  a.red {
	color: red;
	font-weight: bold;
	text-decoration: underline;
  }
  
/* Home Page */
#home-intro {
	background-color: transparent;
	color: #ffffff;
}
#home-container {
	/*
	background-image: url('../images/guitar.png');
	background-position: top center;
	background-size: 1024px;
	background-repeat: no-repeat;
	*/
	max-height: 1000px;
	overflow: hidden;
}
#home-intro p {
	background-color: #000000;
	margin-top: 200px;
}

/* Second Page - Behind the Music */
#behind-the-music {
	background-image: url('../images/behind-the-music.png');
	background-position: top center;
	background-size: 1024px;
	background-repeat: no-repeat;
}

#the-band .card {
	background-color: #000000;
	color: #FFFFFF;
	text-transform: none;
}
.card p {
	margin: 10px 0;
}

a.link-button {
	background-color: #11f806;
	border: solid 2px #af32d6;
	border-radius: 10px;
	padding: 10px 30px;
	margin: 10px auto;
	display: block;
	max-width: 500px;
	color: #af32d6;
	text-decoration: none;
}
a.link-button:visited {
	color: #af32d6;
}
a.link-button:hover {
	text-decoration: none;
}

/* PFP section */
#pfp-content {
	background-color: #000000;
	padding: 30px;
}

/* Access Pass section */
#access-pass-content {
	background-color: #000000;
	padding: 30px;
}
ul#access-pass-points {
	margin: 20px 0;
}

/* Shredifesto */
#shredifesto_wrapper {
	background-color: #000000;
	padding: 30px;
}

h2 {
	text-align: center;
	/* box-shadow: 0 0 10px 10px #af32d6; */
}

p {
	font-size: 18px;
	margin: 0 auto;
	max-width: 900px;
}

#links div {
	text-align: center;
	margin: 10px auto;
	background-color: #11f806;
	border: solid 2px #af32d6;
	border-radius: 10px;
	max-width: 500px;
	padding: 10px 0;
}
#links a:link, #links a:visited {
	color: #af32d6;
	text-decoration: none;
	display: block;
}
#links a:hover {
	font-weight: bold;
}