footer {
	bottom: 0;
	width: 100%;
}
footer p {
	text-align: center;
}
footer a {
	color: #666666;
	margin: 0 10px;
}