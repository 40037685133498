.nav-item {
	margin: 10px 0;
}

#site-nav button {
	background-color: #FFFFFF;
}
#site-nav .nav-link {
	color: #ffffff;
	padding: 0 40px;
}

#site-nav .nav-link.active {
	background-color: #11f806;
	border: solid 2px #af32d6;
	color: #af32d6;
	font-weight: bold;
}